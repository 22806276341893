.nav-toggle .fa-bars::before {
  font-size: 30px;
}

.fb_right {
  z-index: 9999999 !important;
}

@media only screen and (max-width: 1070px) {

}


@media only screen and (max-width: 1023px) {
  header nav {
    top: calc(100% + 15px);

      ul li {
      margin: 0;
      padding: 0;

      a {
        display: block;
        text-align: center;
        padding: 10px;
      }
    }
  }

  nav.secondary {
    display: none;
  }
}

@media only screen and (max-width: 760px) {
  .new_container {
    .row {
      .col_half {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 550px) {


  header .new_container .row {
    &:last-child {
      margin-right: 0;
    }
  }

  .fb_right {
    img {
      width: 30px;
    }
  }

  header {
    box-shadow: none;
  }

  header > .new_container > .row {

    display: flex;
    flex-wrap: wrap;

    .h_logo_box {
      width: calc(100% - 50px);
      order: 1;
    }

    .h_contact_box {
      width: 100%;
      order: 3;
      padding-top: 15px;
      text-align: center;

      .tel, .info {
        font-size: 15px;
        line-height: 18px;
        display: inline-block;
        vertical-align: middle;
      }
    }

    .h_menu_box {
      width: 50px;
      order: 2;
    }

    .nav-toggle {
      margin-top: 7px;
      margin-right: 0;
    }
  }

  footer > .new_container {
    padding: 0;
  }

}