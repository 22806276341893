.top_slide_box, .footer_slide_box {
  padding-top: 85px;
  padding-bottom: 85px;
  background: no-repeat center / cover;

  .top_slider, .footer_slider {
    width: calc(100% - 460px);
    max-width: 100%;

    .slider_owl, .footer_slide {
      width: 550px;
      max-width: 100%;

      h2 {
        color: #FFF;
        font-size: 52px;
        font-weight: 900;
        text-transform: uppercase;
        font-family: Lato, sans-serif;
        margin: 0;
      }

      p {
        font-family: Lato, sans-serif;
        color: #FFF;
        font-size: 17px;
        font-weight: 600;
      }

      .owl-nav {
        position: absolute;

        .owl-next, .owl-prev {
          border: 1px solid #FFF;
          float: left;
          width: 60px;
          height: 40px;
          margin-left: -1px;
          position: relative;
        transition: .3s;

          &::after {
            content: '';
            width: 11px;
            height: 20px;
            background: no-repeat url(../img/arrow-one.png) center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: block;
            margin: auto;
          }

          &:hover {
            background: rgba(255,255,255,0.3);
          }
        }

        .owl-prev {
          margin-left: 0;

          &::after {
            transform: rotate(180deg);
          }
        }
      }

    }
  }

  .top_contact_form, .footer_contact_form {
    width: 460px;
    max-width: 100%;
    background: #FFF;
    padding: 30px;

    form {
      width: 100%;

      /* & > p,
      & > h2 {
        padding-left: 0;
        padding-right: 0;
      } */

      & > h2 {
        color: #4c485a;
        font-family: Lato, sans-serif;
        font-size: 22px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 0 0 5px 0;
      }

      p.co_mam {
        color: #4c485a;
        font-family: Lato, sans-serif;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: -10px;
        padding-bottom: 0;
      }

      p.form_txt {
        margin-bottom: 0;
        padding-bottom: 0;

        input {
          margin: 0;
          border: 1px solid #e4e4e4;
          background: #FFF;
          border-radius: 0;
          padding: 9px 20px;
          font-size: 16px;
          font-weight: 400;
          font-family: Lato, sans-serif;
          height: auto;
        }

      }

      .new_container {
        margin-top: 20px;

        & > .row {
          margin-left: -5px;
          margin-right: -5px;
        }
      }

      .wpcf7-radio {
        display: inline-block;
        vertical-align: middle;

        & > span {
          position: relative;
          overflow: hidden;
          
          & > input {
            position: absolute;
            width: 14px;
            height: 14px;
            top: 6px;
            left: 1px;
            margin: 0;
            opacity: 0;
          }

          & > label,
          & > span {
            margin-left: 0;
            margin-left: 25px;
            display: block;

            &::before {
              content: '';
              position: absolute;
              top: 6px;
              left: 0;
              display: block;
              width: 14px;
              height: 14px;
              overflow: hidden;
              background: url(../img/icon_radio.svg);
              pointer-events: none;
            }
          }

          input:checked + label::before,
          input:checked + span::before {
              background: url(../img/icon_radio_checked.svg);
          }

          & > span {
            pointer-events: none;
          }
        }
      }

      .zgoda_box {
        width: calc(100% - 191px);
        margin-left: 0;

        .l_zgoda {
          width: 16px;
          margin: 0px;

          input {
            margin-bottom: 0;
            display: block;
            width: 16px;
            height: 16px;
          }

          .img_checked {
            position: absolute;
            top: 0;
            left: 0;
            width: 16px;
            height: 16px;
            overflow: hidden;
            background: url(../img/checked.png);
            pointer-events: none;
          }

          input:checked + .img_checked {
            background-position: 16px 0px;
          }

          span {
            display: block;
            width: 16px;
            height: 16px;
            overflow: hidden;
            margin: 0;
          }
        }

        .r_zgoda {
          width: calc(100% - 31px);
          color: #4c485a;
          font-family: Lato, sans-serif;
          font-size: 12px;
          font-weight: 400;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          cursor: pointer;
        }
      }

      .send_box {
        width: 190px;

        input {
          background-color: #4c485a;
          padding: 15px;
          width: 100%;
          text-align: center;
          color: #ffffff;
          font-family: Lato, sans-serif;
          font-size: 14px;
          font-weight: 700;
          text-transform: uppercase;
          border: 0;
          border-radius: 0;
          cursor: pointer;
          transition: .3s;

          &:hover {
            background-color: #302f36;
          }

          & + span {
            display: none;
          }
        }
      }
    }

    .wpcf7-form span.wpcf7-not-valid-tip {
      margin-top: 0;
      font-size: 14px;
    }

    &.no_left {
      margin-left: calc(100% - 460px);
    }
  }
}