/* *************************************************************************
    Fonts
************************************************************************* */
/*
@font-face {
    font-family: Lato Regular;
  src: url('../fonts/webfonts/Lato-Regular-webfont.eot');
  src: url('../fonts/webfonts/Lato-Regular-webfont.eot?iefix') format('eot'),
       url('../fonts/webfonts/Lato-Regular-webfont.woff') format('woff'),
       url('../fonts/webfonts/Lato-Regular-webfont.ttf') format('truetype'),
       url('../fonts/webfonts/Lato-Regular-webfont.svg#webfont') format('svg'); 
}
*/
/* *************************************************************************
    General
************************************************************************* */
@charset "utf-8";
body {
  /*font-family: 'Lato Regular', sans-serif;*/
  font-family: "LatoLatinWeb";
  color: #4c4859;
  overflow-x: hidden;
}

/*
body.kontakt {
  text-align: center;
}
*/

iframe {
    height: 19vw;
    width: 97%;
}

h1, h2, h4, h5 {
  text-transform: uppercase;
  padding: 0 10px;
  font-family: 'LatoLatinWeb', sans-serif;
}

a, a:hover {
  color: inherit;
}

ul {
  padding-left: 15px;
}

article {
  margin-bottom: 30px;
}

h3 img {
  margin-right: 20px;
  float: left;
}
h3 {
  text-transform: uppercase;
  padding-left: 10px;
  line-height: 75%;
}

/*
h4 {
  margin-left: 10px;
}
*/



.smaller-headline {
  font-size: 16px;
  line-height: 90%;
  text-align: right;
  float: right;
}

.light {
  font-weight: 300;
}

.row, .fullwidth {
  /*max-width: 100%;*/
  max-width: 1366px;
  margin-left: auto;
  margin-right: auto;
}

.relative {
  position: relative;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.yellow-txt {
  color: #ffb514;
}

.no-margin {
  margin: 0;
} 

.borders-1 {
  border-top: 1px #0f0f0f solid;
  border-bottom: 1px #0f0f0f solid;
}

.justify {
  text-align: justify;
}

.nav-urzadzenia-foot {
  cursor: pointer;
}

.right-column {
  /*padding: 5vw 2vw;*/
}

.right-column img {
  width: 80%;
}

.extra-img {
    width: 160%;
    margin-left: -80%;
    max-width: none;
}

.header-block {
  margin: 15px 0;
}

.box-bg {
  transition: all 300ms;
}

.hidden {
  display: none;
}

.faded {
  opacity: 0;
}

.visible {
  opacity: 1;
  visibility: visible;
}

.uppercase {
  text-transform: uppercase;
}

.content-img {
  margin-left: calc(10px + 0.9em);
}

.btn {
  display: inline-block;
  border: 1px #fff solid;
  padding: 10px;
  margin: 0 1vw;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
}

.btn:hover {
  color: inherit;
}

.btn-full {
  width: 100%;
  margin: 5px 0;
  font-weight: 500;
    font-size: 20px;
    font-family: lato;
    line-height: 27px;
    
}

.contact-form input,
.contact-form textarea,
.contact-form select {
  border-radius: 0;
  border: 1px #000 solid;
}

.btn-send {
  background: #fff;
  border: 1px #000 solid;
  padding: 10px;
  border-radius: 0;
  float: right;
}

.btn-red:visited, 
.btn-red {
  border: 1px #ed1c24 solid;
  color: #ed1c24;
}

.btn-blue:visited, 
.btn-blue {
  border: 1px #0079c1 solid;
  color: #0079c1;
}

.btn-lightblue:visited, 
.btn-lightblue {
  border: 1px #26ccc0 solid;
  color: #26ccc0;
}

.btn-yellow:visited, 
.btn-yellow {
  border: 1px #ffb514 solid;
  color: #ffb514;
}

.btn-dark:visited, 
.btn-dark {
  border: 1px #4c485a solid;
  color: #FFF;
  background-color: #4c485a;
  padding: 11px 25px;
  margin: 0 15px 15px 0;
  transition: .3s;
  font-size: 14px;
  font-weight: 700;

  &:hover {
    color: #4c485a;
    background-color: #FFF;
  }
}

.img_more {
    opacity: 0;
}

.img_thumb:hover .img_more {
    opacity: 1;
}


ul {
  list-style: none;
}

.section {
  margin-top: 2vw;
  margin-bottom: 0; /*3vw;*/
  padding: 15px;
}

.cookies-notification p {
  display: inline-block;
}

.cookies-notification {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #221e2c;
  color: #fff;
  text-transform: uppercase;
  padding: 10px 20px;

  display: none;
}

.closed {
  display: none;
}

.vertical-space {
  margin-top: 2vw;
  margin-bottom: 2vw;
}

.offer-select {
  
  padding: 0 20px !important;
}

.head-column {
  padding-right: 15px !important;
}

.head-column img {
  width: 100%;
}

.uppercase {
  text-transform: uppercase;
}

.small-padding {
  padding-left: 5px;
}

.blue {
  color: #0079c1;
}

.lightblue {
  color: #26ccc0;
}

.greyblue {
  color: #6c85bd;
}

.red {
  color: #ed1c24;
}

p, h4 {
  padding: 10px;
  /*text-align: justify;*/
}

p {
  font-size: 17px;
}

form {
  width: 90%;
}

.center-flex {
  display: flex;
  align-items: center;
}

.no-padding {
  padding: 0!important;
}

.features-img {
  margin-left: 10px;
}

.pd25 {
  padding: 25px;
}

.padding-left {
  padding: 0;
  padding-left: 10px;
}

.no-margin {
  margin: 0;
}

/* *************************************************************************
    Tooltips
************************************************************************* */

.tooltipster-sidetip .tooltipster-box {
  background: #fff;
  color: #0070b8;
  border: 2px #0070b8 black;
  border-radius: 0;
}

.tooltipster-sidetip .tooltipster-content {
  color: #0070b8;
  line-height: 18px;
  padding: 6px 14px;
}

/* *************************************************************************
    Visibility
************************************************************************* */
@media (max-width: 640px) {
  .hide-sm {
    display: none;
  }
    

}

@media (min-width: 641px) and (max-width: 1023px) {
  .hide-md {
    display: none;
  }
}

@media (min-width: 1024px) {
  .hide-lg {
    display: none;
  }
}

/* *************************************************************************
    Header / Footer
************************************************************************* */

header {
  padding: 2vw;
}

header .logo {
  text-transform: uppercase;
  line-height: 110%;
  font-size: 1.9vw;
}

nav ul {

}

nav ul li {
  display: inline-block;
  padding: 10px 20px;
  
  text-transform: uppercase;
  margin: 10px;

  font-size: 20px;
}

nav a, nav a:hover {
  color: inherit;
}

.menu-active,
header .current-menu-item {
  border: 1px #dadada solid;
}

.home-widget .current-menu-item {
  border-left: 0;
    border-right: 0;
}


footer {
  background: #4c4859;
  width: 100%;
  text-transform: uppercase;
  color: #fff;
  padding: 3vw 20px !important;
}

footer ul li {
  margin-bottom: 2vw;
}

.credits {
  text-transform: none;
}

.footer-ico {
  opacity: 0.5;
  margin-right: 1.5vw;
}

nav.secondary {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /*height: 75px;*/
  background: rgba(0,0,0,0.4);
}

nav.secondary ul {
  float: right;
  margin: 0;
}

nav.secondary ul li {
  display: inline-block;
  color: #fff;
  margin: 0 2vw;
  line-height: 100%;
  text-align: center;
  cursor: pointer;
  transition: background 300ms;
}

.head-partners {
    position: absolute;
    top: 70px;
    right: 40px;
    text-align: center;
    width: 100px;
}

.head-partners img {
  display: block;
  margin: 10px 0;
}

.border-bottom-orange {
  border-bottom: 3px #f9a61c solid;
}

.border-bottom-orange:hover,
.nav-somfy.active-nav {
  background: #f9a61c;
}

.border-bottom-red {
  border-bottom: 3px #ed1b24 solid;
}

.border-bottom-red:hover,
.nav-velux.active-nav {
  background: #ed1b24;
}

.border-bottom-green {
  border-bottom: 3px #26ccc0 solid;
}

.border-bottom-green:hover,
.nav-hitachi.active-nav {
  background: #26ccc0;
}

.border-bottom-blue {
  border-bottom: 3px #0079c0 solid;
}

.border-bottom-blue:hover,
.nav-philips.active-nav {
  background: #0079c0;
}

h3.lato {
    color: #4c4859;
  font-family: 'Lato', sans-serif;
    font-size: 38px;
    text-transform: uppercase;
    font-weight: 300;
    margin-top: 20px;
    line-height: 41px;
    
}


.btn-max {
    display: inline-block;
    max-width: 400px;
    margin-right: 0;
}

.header-block-right {
    text-align: right;
}

.margin_top_70 {
    margin-top: 70px;
}

/* *************************************************************************
    Kontakt
************************************************************************* */

.vertical-space .row {
  margin: 2vw 0;
}

/* *************************************************************************
    Homepage
************************************************************************* */

.yellow-borders {
  border: 2px #ffb514 solid;
}

.red-borders {
  border: 2px #ed1c24 solid;
}

.black-borders {
  border: 2px #4c4d4f solid;
}

.blue-borders {
  border: 2px #0079c2 solid;
}

.home-widget li {
  width: 100%;
  border-top: 1px #6c85bd solid;
  border-bottom: 1px #6c85bd solid;
  font-size: 1.85vw;
  color: #6c85bd;
  transition: all 300ms;
}

.home-widget li:nth-child(2) {
  border: 0;
}

.home-widget li:hover {
  background: #6c85bd;  
  color: #fff;
}

.home-widget li a {
  color: inherit;
  text-decoration: none;
}

p.justify img {
  width: 60px;
}

iframe {

  height: 23vw;
}

@media (max-width: 1333px) {
    
    nav ul li {
  display: inline-block;
  padding: 10px 10px;
    }

    iframe {

        height: 34vw;
    }
}

@media (max-width: 640px) {

    
    iframe {
        height: 56vw;
    }
}

/* *************************************************************************
    Slider
************************************************************************* */

.slick-slider {
  overflow: hidden;
}

.inner-content {
  position: absolute;
  width: 90%;
  height: 90%;
  top: 5%;
  left: 5%;
}

.inner-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.inner-title span {
  display: none;
  padding: 10px 15px;
  background: rgba(255,255,255,0.5);
  text-align: center;
  color: #4c4d4f;
  text-transform: uppercase;
  font-weight: 800;
}

.yellow-borders:hover .inner-title {
  border: 2px #ffb514 solid;
}

.red-borders:hover .inner-title {
  border: 2px #ed1c24 solid;
}

.black-borders:hover .inner-title {
  border: 2px #4c4d4f solid;
}

.blue-borders:hover .inner-title {
  border: 2px #0079c2 solid;
}

.yellow-borders:hover .inner-title img {

}

.yellow-borders:hover #home-box-1 img {
  display: none;
}

.yellow-borders:hover #home-box-1 span {
  display: block;
}

.red-borders:hover #home-box-2 img {
  display: none;
}

.red-borders:hover #home-box-2 span {
  display: block;
}

.black-borders:hover #home-box-3 img {
  display: none;
}

.black-borders:hover #home-box-3 span {
  display: block;
}

.blue-borders:hover #home-box-4 img {
  display: none;
}

.blue-borders:hover #home-box-4 span {
  display: block;
}

.slider img {
  width: 100%;
}

/* *************************************************************************
    Velux
************************************************************************* */

.offer-item {
  border-top: 1px #000 solid;
  border-bottom: 1px #000 solid;
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
  margin: 1.5vw 0;
  width: 100%;

  
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
}

.red-points li,
.blue-points li,
.lightblue-points li,
.yellow-points li,
.yellow-points ul li
 {
  position: relative;
}
 
ul.yellow-points li:before,
.yellow-points li:before {
  content: '';
  display: block;
  position: absolute;
  left: -15px;
  width: 8px;
  height: 8px;
  top: 9px;
  /*transform: translateY(-50%);*/
  background: #ffb514;
}
ul.red-points li:before,
.red-points li:before {
  content: '';
  display: block;
  position: absolute;
  left: -15px;
  width: 8px;
  height: 8px;
  background: #ed1c24;
  /*top: 50%;
  transform: translateY(-50%) ;*/
  top: 9px;
}

ul.blue-points li:before,
.blue-points li:before{
  content: '';
  display: block;
  position: absolute;
  left: -15px;
  width: 8px;
  height: 8px;
  top: 9px;
/*  top: 50%;
  transform: translateY(-50%);*/
  background: #0079c1;
}

ul.lightblue-points li:before,
.lightblue-points li:before {
  content: '';
  display: block;
  position: absolute;
  left: -15px;
  width: 8px;
  height: 8px;
  top: 9px;
/*  top: 50%;
  transform: translateY(-50%);*/
  background: #26ccc0;
}

.red-points li.no-point:before {
  display: none;
}

.yellow-points li.no-point:before {
  display: none;
}

.flex-beetween {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

/* *************************************************************************
    Video
************************************************************************* */

video {
  width: 100%;
}

/* *************************************************************************
    Contact
************************************************************************* */
  
  .mail-sent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 20px;
    background: green;
    color: #fff;
    text-align: center;
    font-weight: 800;
    padding: 5px;
  }


/* *************************************************************************
    Responsive
************************************************************************* */

@media only screen and (max-width: 1023px) {

header .logo {
  font-size: 5vw;
}

nav.mobile-hidden {
  display: none;
}

nav {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  z-index: 100;
  background: #fff;
}

nav ul {
  width: 100%;
  margin: 0;
}

nav ul li {
  display: block;
  text-align: center;
  width: 100%;
  padding: 10px 0;
  margin: 0;
  border-top: 1px #000 solid;
  border-bottom: 1px #000 solid;
}

nav.secondary {
  transform: none;
}

.home-widget li {
  font-size: 4.5vw;
}

.menu-active {
  background: #4c4859;
  color: #fff;
}

.nav-toggle {
  margin-right: 50px;
}

.offer-item {
/*  font-size: 6vw;*/
}

form {
  width: 100%;
}

}



.new_container {
  width: 1140px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
  max-width: 100%;

  &.mini {
    width: 800px;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .row {
    margin-left: -15px;
    margin-right: -15px;

    & > div:not(.row) {
      display: inline-block;
      vertical-align: top;
      margin-right: -4px;
      padding-left: 15px;
      padding-right: 15px;
    }


    .col_half {
      width: 50%;
    }

    &.middle-child {
      & > div{
        vertical-align: middle !important;
      }
    }

    &.bottom-child {
      & > div{
        vertical-align: bottom !important;
      }
    }
  }
}

.widget_breadcrumb_navxt {
  padding-top: 25px;
}

.top_slide_box_page {
  min-height: 300px;
  width: 100%;
  display: block;
  background: no-repeat center / cover;
}

.breadcrumb_sec {
  padding-top: 25px;
  padding-bottom: 25px;

  .breadcrumb-widget {
    margin-bottom: 0;
  }
}

.page-template-paget_kontakt {
  form.wpcf7-form {
    p {
      margin-bottom: 0;
      padding-bottom: 0;

    }

    .recaptcha_style {
      padding: 5px 10px;

      & > div {
        width: 316px !important;
      }
    }
  }
}

.nor {
  margin-right: 0 !important;
}

.recaptcha_style {
  text-align: right;
  
  & > div {
    display: inline-block;
    overflow: hidden;
    margin-right: -4px;
  }
}

@media all and (max-width: 567px) {
  #ct-ultimate-gdpr-cookie-popup #ct-ultimate-gdpr-cookie-accept,
  #ct-ultimate-gdpr-cookie-popup #ct-ultimate-gdpr-cookie-read-more,
  #ct-ultimate-gdpr-cookie-popup #ct-ultimate-gdpr-cookie-change-settings {
    width: 100% !important;
    text-align: center !important;
    float: left !important;
    margin: 6px 0 !important;
  }
}

.ct-ultimate-gdpr-cookie-buttons > div {
  color: #fff !important;
}

.zgoda_box_contact {
  .l_zgoda {
    display: inline-block;
    vertical-align: middle;
    width: 45px;
  }
  .r_zgoda {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 60px);
  }
}