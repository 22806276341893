@media only screen and (max-width: 1070px) {
  .top_slide_box, .footer_slide_box {
    padding-top: 35px;
    padding-bottom: 35px;

    & > .new_container {
      padding-left: 30px;
      padding-right: 30px;
    }

    .top_slider, .footer_slider {
      width: calc(100% - 360px);

      .slider_owl, .footer_slide {
      }
    }

    .top_contact_form, .footer_contact_form {
      width: 360px;

      &.no_left {
        margin-left: calc(100% - 360px);
      }
    }
  }
}

@media only screen and (max-width: 920px) {
  .top_slide_box, .footer_slide_box {

    & > .new_container {
    }

    .top_slider, .footer_slider {
      

      .slider_owl, .footer_slide {
        h2 {
          font-size: 32px;
        }
      }
    }

    .top_contact_form, .footer_contact_form {
      
    }
  }
}

@media only screen and (max-width: 760px) {
  .top_slide_box, .footer_slide_box {

    & > .new_container {
      padding-left: 15px;
      padding-right: 15px;
    }

    .top_slider, .footer_slider {
      width: 100%;
      padding-bottom: 25px;

      .slider_owl, .footer_slide {
        .owl-nav {
          position: relative;
        }

        h2 {
          
        }
      }
    }

    .top_contact_form, .footer_contact_form {
      width: calc(100% - 30px);
      margin-left: 15px !important;
      margin-right: 15px;

      &.no_left {
        margin-left: 15px;
      }

      form {
        .zgoda_box {
          width: 100%;
          margin-top: -10px;
        }

        .send_box {
          width: 100%;
          margin-top: 10px;
        }
      }
    }
  }
}