.page-template-paget_start {

  .top_partners_box {

    .partner_box {
      width: 25%;
      text-align: center;
      padding: 15px;

      img {
        max-width: 100px;
        max-height: 100px;
      }
    }

    &_plus {
      padding-bottom: 30px;

      .row {
        display: flex;
        flex-wrap: wrap;

        .partner_box {
          width: calc(50% - 30px);
          padding: 15px;
          border: 1px solid #e4e4e4;
          margin: 15px;
          position: relative;

          p {
            color: #4c485a;
            font-family: Lato, sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 45px;
            padding-left: 0;
            padding-right: 0;


            &:last-child {
              padding-bottom: 0;
            }
          }

          div.text-right {
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }
      }
    }
  }

  .boxy_tresc_center {

    .row {

      margin-bottom: 60px;

      &:nth-child(2n+2){
        display: flex;
        flex-wrap: wrap;  
        .img {
          order: 2;
        }    
      }
    }

    .img {

    }

    .text {
      

      h2 {
        color: #4c485a;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        font-family: Lato, sans-serif;
      }

      p {
        color: #4c485a;
        font-family: Lato, sans-serif;
        font-size: 17px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 0;

        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }

  .blog_sec {
    padding-top: 30px;
    padding-bottom: 30px;

    .title {
      h2 {
        font-size: 31px;
        font-weight: 300;
        line-height: 40px;
        text-transform: uppercase;
      }
    }

    .box_single_post {
      width: 33.33%;
    }

    .btn_blog {
      transition: .3s;
      border: 1px solid #4c485a;
      text-transform: uppercase;
    }
  }

  .top_product_box {
    padding-top: 50px;
    padding-bottom: 30px;

    .title {
      padding-bottom: 20px;
    }

    .img {
      width: 33.33%;
    }

    .spec {
      width: 66.67%;

      .spec_box {
        width: 50%;
        padding-top: 30px;
        padding-bottom: 30px;

        .row > div {
          color: #4c485a;
          font-family: Lato, sans-serif;
          font-size: 20px;
          font-weight: 700;
          line-height: 26px;
        }
      }
    }
  }

  .blog_sec.films {
    iframe {
      width: 100%;
      height: 250px;
    }
  }

}

.brosz_sec {
  color: #FFF;
  margin-bottom: 50px;

  a {
    color: #FFF;
  }

  .bg_color {
    background: no-repeat center / cover;
    padding: 100px;
    font-family: "Lato Latin", sans-serif;
    position: relative;
    z-index: 0;

    &::after {
      content: '';
      z-index: -1;
      position: absolute;
      top: 40px;
      right: 40px;
      bottom: 40px;
      left: 40px;
      border: 1px solid #FFF;
    }
  }

  h2 {
    width: 100%;
    font-size: 52px;
    font-weight: 900;
    line-height: 56px;
    margin: 0 0 20px 0;
    padding: 0;
    text-transform: none;
  }

  p {
    display: block;
    width: 100%;
    max-width: 620px;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    margin: 0 0 20px 0;
    padding: 0;
  }

  a.click_blank {
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 10px 15px;
    border: 1px solid #e4e4e4;
    transition: .3s;

    &:hover {
      background-color: #FFF;
      color: #4c485a;
    }
  }

}