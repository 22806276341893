.page-template-page_benefits {
  
  .korzysci_center_box {
    padding-bottom: 25px;

    .new_container {
      margin-bottom: 50px;
    }

    .row {
      margin-bottom: 25px;

      &.title { 
        margin-bottom: 15px;

        h2 {
          color: #4c485a;
          font-family: Lato, sans-serif;
          font-size: 24px;
          font-weight: 700;
          line-height: 32px;
          text-transform: uppercase;
          margin: 0;
          padding: 0 15px;
        }
      }

      .img {

      }

      .text {
        
        color: #4c485a;
        font-family: Lato, sans-serif;

        h2 {
          font-size: 24px;
          font-weight: 700;
          line-height: 32px;
          text-transform: uppercase;
          padding: 0;
          margin-bottom: 15px;
        }

        p {
          font-size: 17px;
          font-weight: 400;
          line-height: 24px;
          padding: 0;
          margin-bottom: 15px;
        }
      }

      &:nth-child(2n+2){
        display: flex;
        flex-wrap: wrap;  
        .img {
          order: 2;
        }    
      }
    }
  }
}