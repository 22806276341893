@media only screen and (max-width: 920px) {
	.page-template-paget_start .blog_sec {

		.row + .row {
			text-align: center;
		}

		.box_single_post {
			width: 50%;
		}
	}

	.page-template-paget_start .top_product_box {
		.img {
			width: 100%;
		}

		.spec {
			width: 100%;

			& > .row {
				max-width: 80%;
				margin: 0 auto;
				display: block;
			}
		}
	}
}

@media only screen and (max-width: 760px) {

	.page-template-paget_start .top_partners_box .partner_box img {
		max-width: 100%;
	}

	.page-template-paget_start .boxy_tresc_center {

		.row {
			margin-bottom: 25px;
		}

		.img {
			order: 1;
		}

		.text {
			margin-top: 15px;
			order: 2;
		}

		.row:nth-child(2n+2) .img {
			order: 1;
		}
	}

	.page-template-paget_start .top_partners_box_plus .row .partner_box {
		width: 100%;
	}

	.page-template-paget_start .blog_sec .box_single_post {
		width: 100%;
	}

	.page-template-paget_start .top_product_box {

		.spec {
			width: 100%;

			& > .row {
				max-width: 100%;
				margin: 0 auto;
				display: block;

				.spec_box {
					width: 100%;
					padding: 15px 0 0 0;

					.row {
						div:first-child {
							width: 70px;
						}

						div:last-child {
							width: calc(100% - 71px);
						}
					}
				}
			}
		}
	}

	.brosz_sec {
	  color: #FFF;
	  margin-bottom: 20px;

	  .bg_color {
	    padding: 50px;

	    &::after {
	      top: 25px;
	      right: 25px;
	      bottom: 25px;
	      left: 25px;
	    }
	  }

	  h2 {
	    width: 100%;
	    font-size: 36px;
	    line-height: 42px;
	  }
	}

}