.page-template-page_devices {
  .head_opis { 
    color: #4c485a;
    font-family: Lato, sans-serif;
    padding-bottom: 25px;

    h2 {
      color: #4c485a;
      font-family: Lato, sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      text-transform: none;
    }

    p {
      color: #4c485a;
      font-family: Lato, sans-serif;
      font-size: 17px;
      font-weight: 600;
      line-height: 24px;
      padding-top: 0;
      margin-bottom: 0;
    }
  }

  .partnerzy_center {
    padding-top: 25px;
    padding-bottom: 25px;

    .row {
      margin-bottom: 60px;

      .img {

      }

      .text {
        
        color: #4c485a;
        font-family: Lato, sans-serif;

        & > div:first-child img {
          margin-bottom: 25px;
        }

        h2 {
          font-size: 24px;
          font-weight: 700;
          line-height: 32px;
          text-transform: uppercase;
          padding: 0;
          margin-bottom: 15px;
        }

        p {
          font-size: 17px;
          font-weight: 400;
          line-height: 24px;
          padding: 0;
          margin-bottom: 15px;
        }
      }

      &:nth-child(2n+2){
        display: flex;
        flex-wrap: wrap;  
        .img {
          order: 2;
        }    
      }
    }
  }
}