header {
	-webkit-box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.5);
	-moz-box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.5);
	box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.5);
	border-bottom: 1px solid rgba(0,0,0,0.5);
	padding: 15px 0;

	& div > .row {
		margin-left: -15px;
		margin-right: -15px;

		& > div {
			display: inline-block;
			vertical-align: middle;
			margin-right: -4px;
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	.h_logo_box {
		width: 250px;
	}

	.h_contact_box {
		width: 225px;

		.tel {
			color: #ffbe13;
			font-family: Roboto, sans-serif;
			font-size: 18px;
			font-weight: 700;
			display: block;
		}

		.info {
			color: #4c485a;
			font-family: Roboto, sans-serif;
			font-size: 12px;
			text-transform: uppercase;
			font-weight: 400;
			display: block;

			strong {
				font-weight: 700;
			}
		}
	}

	.h_menu_box {
		width: calc(100% - 475px);
		text-align: right;
	}

	.logo {
		font-size: 22px;
		font-weight: 700;
		font-family: Roboto, sans-serif;
	}

	nav {
		ul {
			margin: 0;
			padding: 0;

			li {
				color: #4c485a;
				font-family: Lato, sans-serif;
				font-size: 14px;
				font-weight: 700;
				margin: 0 10px;

				a {
					color: #4c485a;
					text-transform: none;
				}
			}
		}
	}

	/* & > .new_container > div > div {
		margin-right: -2px !important;
	} */
}


html.active_mobile,
html.active_mobile body {
    overflow: hidden;
    position: relative;
    height: 100%;
}

.menu_devices_top {
	position: absolute;
	left: 0;
	right: 0;
	overflow: hidden;
	padding-bottom: 50px;
	z-index: 9999999;
	display: none;

	ul {
		width: 100%;
		margin: 0;
		padding: 0;
		background-color: #FFF;
		display: flex;
		flex-wrap: wrap;
		-webkit-box-shadow: 0px 12px 20px 5px rgba(0,0,0,0.5);
		-moz-box-shadow: 0px 12px 20px 5px rgba(0,0,0,0.5);
		box-shadow: 0px 12px 20px 5px rgba(0,0,0,0.5);

		li {
			display: inline-block;
			vertical-align: top;
			/* margin-right: -4px;	 */
			position: relative;
			text-align: center;
			transition: .3s;
			opacity: .8;
			-webkit-box-shadow: inset -2px 0px 4px -2px #b7b7b7;
			-moz-box-shadow: inset -2px 0px 4px -2px #b7b7b7;
			box-shadow: inset -2px 0px 4px -2px #b7b7b7;
			border-right: 1px solid #b7b7b7;

			&:last-child {
				box-shadow: none;
				border-right: 0;
			}

			&:hover {
				opacity: 1;

				.title {
					color: #000;
				}
			}

			.color_belt {
				height: 2px;
				width: 100%;
				overflow: hidden;
			}

			.img {
				width: 100%;
				height: 80px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 20px 0 10px 0;

				img {
					max-height: 100%;
					max-width: 100%;
				}
			}

			.title {
				color: #4c485a;
				font-family: Roboto, sans-serif;
				font-size: 18px;
				font-weight: 700;
				padding-bottom: 15px;
			}

			a {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}
		}

		&.columns_3 li {
			width: 33.33%;
		}

		&.columns_4 li {
			width: 25%;
		}

		&.columns_5 li {
			width: 20%;

			.title {
				font-size: 16px;
			}
		}
	}

	.exit_devices {
		display: none !important;
	}
}

.mobile_menu {
	position: absolute;
	top: 0;
	left: 100%;
	z-index: 99999999999;
	transition: left .7s;
	width: 0;
	overflow: hidden;


	&.open {
		overflow: auto;
		height: 100%;
		width: 100%;

		left: 0;
		.menu_box {
		}

		.bg {
			left: 0;
		}

		.bg_white {
			left: calc(100% - 232px);
		}
	}

	.bg {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 0;
		background-color: rgba(0,0,0,0.5);
		left: 100%;
		width: 100%;
		transition: .3s;
	}

	.bg_white {
	    background: #FFF;
		width: 232px;
	    height: 100%;
	    position: fixed;
	    z-index: 0;
		left: 100%;
		transition: .7s;
	}

	.menu_box {
		position: absolute;
		top: 0;
		width: 230px;
		background-color: #FFF;
		color: #4c485a;
		right: 0;
		z-index: 1;

		a {
			color: #4c485a;
		}

		.has_children {
			position: relative;

			.close, .open {
				font-family: Lato,sans-serif;
				font-size: 12px;
				font-weight: 500;
				text-decoration: underline;
				position: absolute;
				top: 0;
				right: 0;
				cursor: pointer;
				display: flex;
				flex-wrap: wrap;
				align-content: center;
				align-items: center;
				height: 53px;
				padding: 0 20px;
			}

			.close {
				display: none;
			}

			& > ul {
				display: none;

				li {
					position: relative;
					display: flex;
					flex-wrap: wrap;
					align-content: center;
					align-items: center;
					min-height: 100px;

					a {
						position: absolute;
						top: 0;
						right: 0;
						left: 0;
						bottom: 0;
						width: 100%;
						height: 100%;
					}

					.color_belt {
						width: 2px;
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
						bottom: 0;
					}

					.img {
						width: 110px;
						text-align: left;
						display: inline-block;
						padding: 15px;
					}

					.title {
						font-family: Roboto, sans-serif;
						font-size: 14px;
						font-weight: 700;
						line-height: 20px;
						display: inline-block;
						padding: 15px;
						width: calc(100% - 110px);
					}
				}
			}
		}

		ul {
			margin: 0;
			padding: 0;
		}

		.menu > ul {

			li a {
				font-family: "Lato", sans-serif;
				font-size: 14px;
				font-weight: 700;
				padding: 15px;
				display: block;
				border-bottom: 1px solid #e4e4e4;
			}

			& > li:last-child a {
				border-bottom: 0;
			}
		}
	}

	.head {
		padding: 15px;

		.tel {
			font-family: Roboto, sans-serif;
			font-size: 18px;
			font-weight: 700;
			line-height: 23px;
			display: block;
			color: #ffbe13;
		}

		.info {
			font-family: Roboto, sans-serif;
			font-size: 12px;
			text-transform: uppercase;
			font-weight: 400;
			display: block;

			strong {
				font-weight: 700;
			}
		}
	}

	.submenu {
		display: none;
	}
}

@media only screen and (max-width: 1024px) {
	.menu_devices_top .exit_devices {
		display: block !important;
		text-align: center;
		background-color: #FFF;
		padding: 10px 15px;
		font-style: 14px;
		font-weight: 700;
		cursor: pointer;
	}

	header .h_menu_box nav {
		display: none !important;
	}

	header .h_contact_box {
		display: none !important;
	}
}

@media only screen and (max-width: 920px) {
	.menu_devices_top ul li {
		.title {
			font-size: 16px;
		}
	}
}

@media only screen and (max-width: 768px) {
	.menu_devices_top ul.columns_1_mobile li {
		width: 100%;
	}

	.menu_devices_top ul.columns_2_mobile li {
		width: 50%;
	}
}