@media only screen and (max-width: 760px) {
	.page-template-page_benefits {
	  .head_opis { 


	    h2 {
	      
	    }

	    p {

	    }
	  }

	  .korzysci_center_box {


	    .row {
	      margin-bottom: 0px;
	      
	      .img {
	        margin-bottom: 25px;
	      }

	      .text {

	        & > div:first-child img {

	        }

	        h2 {

	        }

	        p {

	        }
	      }

	      &:nth-child(2n+2){
	        display: flex;
	        flex-wrap: wrap;  

	        .img {
	          order: 1;
	        }    

	        .text {
	        	order: 2;
	        }
	      }
	    }
	  }
	}
}